import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ActivationEnd, ParamMap, Router} from '@angular/router';
import {ProjectService} from '../../services/project/project.service';
import {Observable, Subscription} from 'rxjs';
import {ProjectData} from '../../entities/Project';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-design-presentation',
  templateUrl: './design-presentation.component.html',
  styleUrls: ['./design-presentation.component.scss']
})
export class DesignPresentationComponent implements OnInit, OnDestroy {


  public project$: Observable<ProjectData>;
  private sub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private projectService: ProjectService) {

    this.sub = this.router.events.subscribe(event => {
        if (event instanceof ActivationEnd) {
          const identifier = event.snapshot.params['token'];
          this.project$ = this.route.paramMap.pipe(
            switchMap((params: ParamMap) => this.projectService.loadProjectData(identifier)),
          );
        }
      }
    );
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
