import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-toggle-element',
  templateUrl: './toggle-element.component.html',
  styleUrls: ['./toggle-element.component.scss']
})
export class ToggleElementComponent implements OnInit {

  @Output() isToggledChange = new EventEmitter<boolean>();

  public toggleValue: boolean;

  constructor() { }

  ngOnInit() {
    this.toggleValue = false;
  }


  toggle(event) {
    this.toggleValue = !this.toggleValue;
    this.isToggledChange.emit(this.toggleValue);
  }

}
