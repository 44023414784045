import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Project, ProjectData} from '../../entities/Project';
import {AngularFirestore} from 'angularfire2/firestore';
import {filter, map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private db: AngularFirestore) {
  }

  public loadProjectData(identiefier: string): Observable<ProjectData> {
    const projectDoc: Observable<Project> = <any>this.db.doc('projects/' + identiefier).valueChanges();

    return projectDoc.pipe(
      filter(e => !!e.versions),
      map((obj: Project) => obj.versions[0])
    );
    // return of({
    //   designFullURL: '/assets/images/spessart-klinik/Mockup_Spessart_KlinikV2_transparent.png',
    //   designMacURL: '/assets/images/spessart-klinik/Mockup_Spessart_KlinikV2.png',
    //   title: 'News Ticker - Spessart-Klinik',
    //   version: '1.0.0',
    //   specs: {
    //     colors: [
    //       {
    //         colorCode: '#00957d',
    //         description: 'Highlights'
    //       }
    //     ],
    //     logoURL: '/assets/images/spessart-klinik/logo.png'
    //   }
    // });
  }
}
