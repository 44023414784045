import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-design-view-wrapper',
  templateUrl: './design-view-wrapper.component.html',
  styleUrls: ['./design-view-wrapper.component.scss']
})
export class DesignViewWrapperComponent implements OnInit {

  @Input() designFullURL: string;
  @Input() designMacURL: string;

  public showMac = false;
  constructor() { }

  ngOnInit() {
  }

  log() {
  }

}
