import {Component, Input, OnInit} from '@angular/core';
import {Spec} from '../../entities/Project';

@Component({
  selector: 'app-design-specs',
  templateUrl: './design-specs.component.html',
  styleUrls: ['./design-specs.component.scss']
})
export class DesignSpecsComponent implements OnInit {

  @Input() specs: Spec;

  constructor() { }

  ngOnInit() {
  }

}
