import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DesignPresentationComponent} from './components/design-presentation/design-presentation.component';
import {DesignSpecsComponent} from './components/design-specs/design-specs.component';
import {SpecColorComponent} from './components/spec-color/spec-color.component';
import {HeaderComponent} from './components/header/header.component';
import {DesignViewFullComponent} from './components/design-view-full/design-view-full.component';
import {DesignViewWrapperComponent} from './components/design-view-wrapper/design-view-wrapper.component';
import {DesignViewMacComponent} from './components/design-view-mac/design-view-mac.component';
import {ToggleElementComponent} from './components/toggle-element/toggle-element.component';
import {FormsModule} from '@angular/forms';
import {AngularFireModule} from 'angularfire2';
import {environment} from '../environments/environment';
import {AngularFirestoreModule} from 'angularfire2/firestore';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    DesignPresentationComponent,
    DesignSpecsComponent,
    SpecColorComponent,
    HeaderComponent,
    DesignViewFullComponent,
    DesignViewWrapperComponent,
    DesignViewMacComponent,
    ToggleElementComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
