import {Component, OnInit} from '@angular/core';
import {LoaderService} from './services/loader/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(private loaderService: LoaderService) {
  }

  ngOnInit(): void {
    this.loaderService.removeLoader();
  }
}
