import {Component, Input, OnInit} from '@angular/core';
import {LoaderService} from '../../services/loader/loader.service';

@Component({
  selector: 'app-design-view-full',
  templateUrl: './design-view-full.component.html',
  styleUrls: ['./design-view-full.component.scss']
})
export class DesignViewFullComponent implements OnInit {

  @Input() src: string;

  constructor(public loaderService: LoaderService) { }

  ngOnInit() {
  }

}
