import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor() { }


  public removeLoader() {
    const loader = document.getElementById('preloader');
    loader.classList.add('invisible');
    setTimeout(() => {
      loader.classList.add('removed');
    }, 1000);
  }
}
